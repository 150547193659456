import React, { useEffect, useState } from 'react'
import "./slick.css";
import "./slick-theme.css";
import Slider from 'react-slick';
import "./scss/NewTestimonial.css"
import Quote from "../../assets/images/quote.svg"
import axios from 'axios';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const NewTestimonial = () => {
    var settings = {
        dots: true,
        infinite: true,
        className: "center",
        centerMode: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        margin: 4,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const testimonial = [1, 2, 3, 4, 5]

    const [data, setData] = useState([])

    const getTestimonialData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_STEELADMIN}/testimonial/getAllTestimonial`
            )
            setData(res.data)
        } catch (error) {
            // console.log(error)
        }
    }

    useEffect(() => {
        getTestimonialData()
    }, [])

    return (
        <div className="Testimonialcarousel">
            <div className="Testimonialhead">
                <h2>Testimonial</h2>
                <h6>What our customers say about us</h6>
            </div>
            <div className="NewTestimonialSlider" id="custom">
                <Slider {...settings}>
                    {data.map((item) =>
                        <div className="Testimonialbox">
  <div className="Testimonialicons">
    <FormatQuoteIcon className='testiicon' sx={{fontSize:'80px'}}/>
  </div>
  <div className="testimonialMessageContainer">
    <h6 className="testimonialMessage">{item.testimonialMessage}</h6>
  </div>
  <h2>{item.testimonialName}</h2>
  <h4>{item.testimonialPosition}</h4>
</div>
)}
                </Slider>
            </div>

        </div>

    )
}

export default NewTestimonial



import { Card, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import CardContent from '@mui/material/CardContent'
import React, { useEffect, useState } from 'react'
import '../Banner/scss/Banner.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Background from '../../assets/images/MainPageBanner.svg'
import CircleIcon from '@mui/icons-material/Circle';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';



function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'none',
                background: 'none',
                marginRight: '9%',
            }}
            onClick={onClick}
        />
    )
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'none',
                background: 'none',
                left: '0',
                zIndex: '5',
                marginLeft: '3%',
            }}
            onClick={onClick}
        />
    )
}

// const usp = [1 2, 3]

const cardData = [1, 2, 3, 4]

let timer


const Banner = () => {
    const [counter, setCounter] = useState(0)

    useEffect(() => {
        clearInterval(timer)
        timer = setInterval(() => {
            if (counter === 200) {
                clearInterval(timer)
                return
            }
            setCounter((prev) => prev + 2)
            counter++
        }, 10)

        return () => clearInterval(timer)
    }, [counter])

    const [data, setData] = useState([])

    const getSliderData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_STEELADMIN}/banner/getAllBannerDetails`
            )
            setData(res.data)
        } catch (error) {
            // console.log(error)
        }
    }

    useEffect(() => {
        getSliderData()
    }, []);

    console.log("getSliderData",data)

    const [dataUSP, setDataUSP] = useState([])

    const getUSPData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_STEELADMIN}/usp/getAllUsp`
            )
            setDataUSP(res.data)
        } catch (error) {
            // console.log(error)
        }
    }

    useEffect(() => {
        getUSPData()
    }, [])

    const settings = {
        // dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed: 3500,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
    }

    const myStyle = {
        background: `url(${data.bannerImage})`,
        backgroundSize: 'cover',
        paddingLeft: '2%',
    }
    const [counterOn, setCounterOn] = useState(false);


const getTrimmedText = (inputText) =>{
    if (inputText.split('.').length > 1) {
        // If there is at least one sentence (i.e., a full stop was found)
        const firstSentence = inputText.split('.')[0].trim(); // Get the first sentence and remove leading/trailing spaces
        return(firstSentence);
      } else {
        // If no full stop was found, use the original text
        return(inputText);
      }
}


    return (
        <div className="banner">
            <Slider {...settings} className="slider">
                {data.map((pics) => (
                    <>
                        <Box
                            key={pics.imgId}
                            className="bannerinner"
                            sx={{
                                background: `linear-gradient(19.39deg, #102c6155 -100%, #020d2247 120.16%), url(${`${process.env.REACT_APP_STEELADMIN}/banner/file/${pics.bannerImageName}`})`,
                                width: '100%',
                                height: '78vh',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                            }}
                        >
                            <div className="bannertext">
                                <Typography
                                    variant="h3"
                                    sx={{
                                        fontWeight: '700',
                                        fontSize: {
                                            xs: '14px',
                                            sm: '16px',
                                            lg: '40px',
                                            lg: '40px',
                                        },
                                        lineHeight: {
                                            xs: '14px',
                                            sm: '21px',
                                            lg: '42px',
                                            lg: '42px',
                                        },
                                        color: '#FFFFFF',
                                    }}
                                >
                                    {pics.banner.bannerName}
                                </Typography>

                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: '400',
                                        fontSize: {
                                            xs: '10px',
                                            sm: '12px',
                                            lg: '20px', 
                                        },
                                        lineHeight: {
                                            xs: '18px',
                                            sm: '18px',
                                            lg: '23px', 
                                        },
                                        color: '#FFFFFF',
                                        paddingTop: '1.5rem',
                                    }}
                                >
                                    {getTrimmedText(pics.banner.bannerDescription) + '.'}
                                </Typography>
                                <Link  to={pics.banner.bannerBtnLink} 
                                style={{ color: 'inherit', textDecoration: 'none' }} className='btnLink' >
                                    <button
                                        className="kmBtn"
                                        style={{
                                            fontWeight: '400',
                                            fontSize: {
                                                xs: '10px',
                                                sm: '12px',
                                                lg: '15px',
                                            },
                                            lineHeight: {
                                                xs: '12px',
                                                sm: '14px',
                                                lg: '23px',
                                            },
                                            color: '#E8E8E8',
                                            backgroundColor: '#16469D',
                                            // paddingTop: 'rem',
                                            border: 'none',
                                            "&:hover": { backgroundColor: "#16469D" }
                                        }}
                                    >
                                        Know More
                                    </button>
                                </Link>
                            </div>
                        </Box>
                    </>
                ))}
            </Slider>
            <Box
                className="boxCard4"
                sx={{
                    mt: { xs: '0%', sm: '0%', md: '0%', lg: '-5%' },
                    position: {
                        xs: 'relative',
                        sm: 'relative',
                        md: 'relative',
                        lg: 'relative',
                        xl: 'absolute',
                    },
                }}
            >
                <Grid
                    container
                    spacing={1}
                    sx={{
                        display: 'flex',
                        // flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' },
                        // flexWrap: 'wrap',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Grid
                        item
                        className="innerBox4"
                        sx={{
                            display: 'flex',
                            // flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' },
                            flexWrap: 'wrap',
                            alignContent: 'center',
                            justifyContent: 'center',
                            width:"100%"
                        }}
                    >
                        {/* --------------------------------------card start--------------------------------------------------------------- */}
                        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                            <Card
                                className="card1 "
                                sx={{
                                    width: {
                                        xs: '95%',
                                        sm: '240px',
                                        md: '350px',
                                        lg: '300px',
                                    },
                                    height: {
                                        sm: '180px',
                                        md: '200px',
                                        lg: '220px',
                                    },
                                    backgroundColor: 'white',
                                    color: 'black',
                                    borderRadius: '4px 0px 0px 4px',
                                    boxShadow: '1px 1px 5px 1px rgba(91, 90, 90, 0.305)',

                                }}
                            >
                                <CardContent
                                    sx={{
                                        px: {
                                            sm: '1rem',
                                            md: '1.5rem',
                                            lg: '2rem',
                                        },
                                        py: {
                                            sm: '1rem',
                                            md: '1.5rem',
                                            lg: '2rem',
                                        },
                                    }}
                                >
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        sx={{
                                            fontSize: {
                                                sm: '30px',
                                                md: '30px',
                                                lg: '38px',
                                            },
                                            lineHeight: {
                                                sm: '30px',
                                                md: '30px',
                                                lg: '21.6px',
                                            },
                                            fontWeight: '500',
                                        }}
                                    >
                                        <Typography variant='h6' sx={{ color: 'black', fontWeight: '500', fontSize: '22px' }}>
                                            {counterOn && <CountUp start={0} end={2000} duration={3} delay={0} />}

                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    fontSize: {
                                                        sm: '16px',
                                                        md: '16px',
                                                        lg: '18px',
                                                    },
                                                    fontWeight: '500',
                                                    pt: '6px',
                                                }}
                                            >

                                                <span style={{ fontSize: '25px' }}>+</span>    {dataUSP[0]?.uspName}
                                            </Typography>
                                        </Typography>
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color="black"
                                        sx={{
                                            fontSize: {
                                                xs: '11px',
                                                sm: '12px',
                                                md: '12px',
                                                lg: '14px',
                                            },
                                        }}
                                    >
                                        {dataUSP[0]?.uspDesc}
                                    </Typography>
                                    <Typography variant='CountIcon' color="#16469D"
                                        sx={{
                                            fontSize: {
                                                xs: '11px',
                                                sm: '12px',
                                                md: '12px',
                                                lg: '14px',
                                            },
                                        }}
                                    >
                                        <CircleIcon sx={{ marginTop: '5%' }} />
                                    </Typography>
                                </CardContent>
                            </Card>
                        </ScrollTrigger>
                        {/* ------------------------------------------ */}
                        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                            <Card
                                sx={{
                                    width: {
                                        xs: '95%',
                                        sm: '240px',
                                        md: '350px',
                                        lg: '300px',
                                    },
                                    height: {
                                        sm: '180px',
                                        md: '200px',
                                        lg: '220px',
                                    },
                                    backgroundColor: 'white',
                                    color: 'black',
                                    borderRadius: '4px 0px 0px 4px',
                                    boxShadow: '1px 1px 5px 1px rgba(91, 90, 90, 0.305)',
                                }}
                                className="card1 "
                            >
                                <CardContent
                                    sx={{
                                        px: {
                                            sm: '1rem',
                                            md: '1.5rem',
                                            lg: '2rem',
                                        },
                                        py: {
                                            sm: '1rem',
                                            md: '1.5rem',
                                            lg: '2rem',
                                        },
                                    }}
                                >
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        sx={{
                                            fontSize: {
                                                sm: '30px',
                                                md: '30px',
                                                lg: '38px',
                                            },
                                            lineHeight: {
                                                sm: '30px',
                                                md: '30px',
                                                lg: '21.6px',
                                            },
                                            fontWeight: '500',
                                        }}
                                    >
                                        <Typography variant='h6' sx={{ color: 'black', fontWeight: '500', fontSize: '22px' }}>
                                            {counterOn && <CountUp start={0} end={17} duration={5} delay={0} />}
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    fontSize: {
                                                        sm: '16px',
                                                        md: '16px',
                                                        lg: '18px',
                                                    },
                                                    fontWeight: '500',
                                                    pt: '6px',
                                                }}
                                            >

                                                <span style={{ fontSize: '25px' }}>+</span>   {dataUSP[1]?.uspName}
                                            </Typography>
                                        </Typography>
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color="black"
                                        sx={{
                                            fontSize: {
                                                xs: '11px',
                                                sm: '12px',
                                                md: '12px',
                                                lg: '14px',
                                            },
                                        }}
                                    >
                                        {dataUSP[1]?.uspDesc}
                                    </Typography>
                                    <Typography variant='CountIcon' color="#16469D"
                                        sx={{
                                            fontSize: {
                                                xs: '11px',
                                                sm: '12px',
                                                md: '12px',
                                                lg: '14px',
                                            },
                                        }}
                                    >
                                        <CircleIcon sx={{ marginTop: '5%' }} />
                                    </Typography>
                                </CardContent>
                            </Card>
                        </ScrollTrigger>
                        {/* ---------------------------------------- */}
                        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                            <Card
                                sx={{
                                    width: {
                                        xs: '95%',
                                        sm: '240px',
                                        md: '350px',
                                        lg: '300px',
                                    },
                                    height: {
                                        sm: '180px',
                                        md: '200px',
                                        lg: '220px',
                                    },
                                    backgroundColor: 'white',
                                    color: 'black',
                                    borderRadius: '4px 0px 0px 4px',
                                    boxShadow: '1px 1px 5px 1px rgba(91, 90, 90, 0.305)',
                                }}
                                className="card1 "
                            >
                                <CardContent
                                    sx={{
                                        px: {
                                            sm: '1rem',
                                            md: '1.5rem',
                                            lg: '2rem',
                                        },
                                        py: {
                                            sm: '1rem',
                                            md: '1.5rem',
                                            lg: '2rem',
                                        },
                                    }}
                                >
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        sx={{
                                            fontSize: {
                                                sm: '30px',
                                                md: '30px',
                                                lg: '38px',
                                            },
                                            lineHeight: {
                                                sm: '30px',
                                                md: '30px',
                                                lg: '21.6px',
                                            },
                                            fontWeight: '500',
                                        }}
                                    >
                                        <Typography variant='h6' sx={{ color: 'black', fontWeight: '500', fontSize: '22px' }}>
                                            {counterOn && <CountUp start={0} end={3000} duration={3} delay={0} />}

                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    fontSize: {
                                                        sm: '16px',
                                                        md: '16px',
                                                        lg: '18px',
                                                    },
                                                    fontWeight: '500',
                                                    pt: '6px',
                                                }}
                                            >

                                                <span style={{ fontSize: '25px' }}>+</span>   {dataUSP[2]?.uspName}
                                            </Typography>
                                        </Typography>
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color="black"
                                        sx={{
                                            fontSize: {
                                                xs: '11px',
                                                sm: '12px',
                                                md: '12px',
                                                lg: '14px',
                                            },
                                        }}
                                    >
                                        {dataUSP[2]?.uspDesc}
                                    </Typography>
                                    <Typography variant='CountIcon' color="#16469D"
                                        sx={{
                                            fontSize: {
                                                xs: '11px',

                                                sm: '12px',
                                                md: '12px',
                                                lg: '14px',
                                            },
                                        }}
                                    >
                                        <CircleIcon sx={{ marginTop: '5%' }} />
                                    </Typography>
                                </CardContent>
                            </Card>
                        </ScrollTrigger>
                        {/* ------------------------------------------- */}
                        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                            <Card
                                sx={{
                                    width: {
                                        xs: '95%',
                                        sm: '240px',
                                        md: '350px',
                                        lg: '350px',
                                    },
                                    height: {
                                        sm: '180px',
                                        md: '200px',
                                        lg: '220px',
                                    },
                                    backgroundColor: '#16469D',
                                    color: 'white',
                                    borderRadius: '0px 4px 4px 0px',
                                    boxShadow: '1px 1px 5px 1px rgba(91, 90, 90, 0.305)',
                                    
                                }}
                                className="card1 "
                            >
                                <CardContent
                                    sx={{
                                        px: {
                                            sm: '1rem',
                                            md: '1.5rem',
                                            lg: '2rem',
                                        },
                                        py: {
                                            sm: '1rem',
                                            md: '1.5rem',
                                            lg: '2rem',
                                        },
                                       
                                    }}
                                >
                                    <Typography
                                        
                                        
                                        
                                    >
                                        <Typography variant='h6' sx={{ color: 'white', fontWeight: '500', fontSize: '30px', marginBottom: '0' }}>
                                            {counterOn && <CountUp start={0} end={200} duration={3} delay={0} />}
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    fontSize: {
                                                        sm: '16px',
                                                        md: '16px',
                                                        lg: '18px',
                                                    },
                                                    fontWeight: '400',
                                                    pt: '6px',
                                                }}
                                            >
                                                <span style={{ fontSize: '30px' }}>+</span> Projects
                                            </Typography>
                                        </Typography>
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color="white"
                                        sx={{
                                            fontSize: {
                                                xs: '11px',
                                                sm: '12px',
                                                md: '12px',
                                                lg: '14px',
                                            },
                                        }}
                                    >
                                        Complete control over products allows us to
                                        ensure our customers recieve the best
                                        quality prices and services.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </ScrollTrigger>
                    </Grid>
                </Grid>
            </Box>


            <Box className='CounterBoxMAin'>
                <div className='CounterStart2'>
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                        <Box className="CardBox">
                            <Typography variant='h6'>
                                {counterOn && <CountUp separator=''  start={0} end={2000} duration={3} delay={0} />}
                                <span style={{ fontSize: '40px' }}> +</span>
                            </Typography>
                            <Typography variant="h5">
                                {dataUSP[0]?.uspName}
                            </Typography>
                        </Box>
                    </ScrollTrigger>
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>

                        <Box className="CardBox" sx={{paddingLeft:'20%'}}>
                            <Typography variant='h6'>
                                {counterOn && <CountUp start={0} end={17} duration={5} delay={0} />}
                                <span style={{ fontSize: '40px' }}> +</span>
                            </Typography>

                            <Typography variant="h5" sx={{paddingTop:'5%'}}>
                                {dataUSP[1]?.uspName}
                            </Typography>

                        </Box>
                    </ScrollTrigger>
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>

                        <Box className="CardBox">
                            <Typography variant='h6'>
                                {counterOn && <CountUp separator='' start={0} end={3000} duration={3} delay={0} />}
                                <span style={{ fontSize: '40px' }}> +</span>
                            </Typography>
                            <Typography variant="h5">
                                {dataUSP[2]?.uspName}
                            </Typography>

                        </Box>
                    </ScrollTrigger>
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>

                        <Box className="CardBox" sx={{paddingLeft:'20%'}}>
                            <Typography variant='h6'>
                                {counterOn && <CountUp start={0} end={200} duration={3} delay={0} />}
                                <span style={{ fontSize: '40px' }}>+</span>
                            </Typography>
                            <Typography variant="h5" sx={{paddingTop:'5%'}}>
                                Projects
                            </Typography>

                        </Box>
                    </ScrollTrigger>

                </div>
            </Box>
        </div>
    )
}

export default Banner
